import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Button, Col, Row, Modal, ModalBody, ModalHeader } from 'shards-react';
import Slider from 'react-slick';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import validator from 'validator';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import { Input } from '../ui-lib';
import { useWindowDimensions } from '../utils';
import PriceSlide from '../components/priceSlide';
import Separator from '../components/separator';
import heroImg from '../assets/images/header.png';
import presImg from '../assets/images/side-card.png';
import oneImg from '../assets/images/one.png';
import twoImg from '../assets/images/two.png';
import threeImg from '../assets/images/three.png';
import moneyImg from '../assets/images/money.png';
import locationImg from '../assets/images/location.png';
import mailImg from '../assets/images/mail.png';
import caseImg from '../assets/images/case.png';
import contractImg from '../assets/images/contract.png';
import phoneImg from '../assets/images/phone.png';
import comunityImg from '../assets/images/comunity.png';

import messages from '../translations/home';

const IndexPage = () => {
  const intl = useIntl();

  const [settings, setSettings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  });

  const { width } = useWindowDimensions();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalState, setModalState] = useState('neutral');

  useEffect(() => {
    setIsSmallScreen(width < 992);
    if (width < 992) {
      setSettings({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      });
    } else if (width < 1200) {
      setSettings({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
      });
    } else {
      setSettings({
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
      });
    }
  }, [width]);

  return (
    <Layout>
      <SEO title="Home" />
      <Row className="align-items-center">
        <Col
          xl="6"
          lg="6"
          sm="12"
          className={isSmallScreen ? 'header-image' : ''}
        >
          <h2>
            <span className="hero-bold">
              {intl.formatMessage(messages.heroTitleP1)}
            </span>{' '}
            {intl.formatMessage(messages.heroTitleP2)}
          </h2>
          <div className="sub-1 mb-5">
            {intl.formatMessage(messages.heroSub)}
          </div>
          <div className={`flex-row ${isSmallScreen ? 'text-center' : ''}`}>
            <Button
              className={`${isSmallScreen ? '' : 'mr-3'} mb-3 hero-btn-primary`}
              theme="success"
              onClick={() => {
                navigate('/contract');
              }}
            >
              {intl.formatMessage(messages.heroBtnPrimary)}
            </Button>
            <Button
              className="hero-btn-secondary mb-3"
              theme="light"
              onClick={() => setOpenModal(true)}
            >
              {intl.formatMessage(messages.heroBtnSecondary)}
            </Button>
          </div>
        </Col>
        {!isSmallScreen && (
          <Col xl="6" lg="6">
            <Image src={heroImg} width="100%" />
          </Col>
        )}
      </Row>
      <Separator />
      <Row>
        {!isSmallScreen && (
          <Col lg="4" className="d-flex align-items-center">
            <Image src={presImg} width="100%" />
          </Col>
        )}
        <Col lg="8">
          <h3>{intl.formatMessage(messages.presTitle)}</h3>
          <div className="sub-1 mb-3">
            {intl.formatMessage(messages.presSub)}
          </div>
          <div className="pt-5 text-justify">
            {intl.formatMessage(messages.presBody)}
          </div>
        </Col>
      </Row>
      <Separator />
      <Row>
        <Col xs="12" className="text-center mb-5">
          <h3>{intl.formatMessage(messages.contractTitle)}</h3>
          <div className="sub-1">
            {intl.formatMessage(messages.contractSub)}
          </div>
        </Col>
        <Col lg="4" xs="12" className="text-center">
          <Image src={oneImg} className="mr-3" />
          <div className="mt-5">{intl.formatMessage(messages.stepOne)}</div>
        </Col>
        <Col
          lg="4"
          xs="12"
          className={`text-center ${isSmallScreen ? 'mt-5' : ''}`}
        >
          <Image src={twoImg} className={isSmallScreen ? '' : 'mt-3'} />
          <div className="mt-5">{intl.formatMessage(messages.stepTwo)}</div>
        </Col>
        <Col
          lg="4"
          xs="12"
          className={`text-center ${isSmallScreen ? 'mt-5' : ''}`}
        >
          <Image src={threeImg} />
          <div className="mt-5">{intl.formatMessage(messages.stepThree)}</div>
        </Col>
      </Row>
      <Separator />
      <Row className="mb-5">
        <Col xl="12" className="text-center">
          <h3>{intl.formatMessage(messages.servicesTitle)}</h3>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xl="4" className="text-center">
          <Image src={moneyImg} className="mb-3" />
        </Col>
        <Col xl="7">
          <h4 className={isSmallScreen ? 'text-center' : ''}>
            {intl.formatMessage(messages.feesTitle)}
          </h4>
          <div className="text-justify">
            {intl.formatMessage(messages.feesBody)}
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        {!isSmallScreen && <Col lg="1" />}
        {isSmallScreen && (
          <Col lg="4" className="text-center">
            <Image src={locationImg} className="mb-3" />
          </Col>
        )}
        <Col lg="7">
          <h4 className={isSmallScreen ? 'text-center' : ''}>
            {intl.formatMessage(messages.addressTitle)}
          </h4>
          <div className="text-justify">
            {intl.formatMessage(messages.addressBody)}
          </div>
        </Col>
        {!isSmallScreen && (
          <Col lg="4" className="text-center">
            <Image src={locationImg} className="mb-3" />
          </Col>
        )}
      </Row>
      <Row className="mb-5">
        <Col lg="4" className="text-center">
          <Image src={mailImg} className="mb-3" />
        </Col>
        <Col lg="7">
          <h4 className={isSmallScreen ? 'text-center' : ''}>
            {intl.formatMessage(messages.mailTitle)}
          </h4>
          <div className="text-justify">
            {intl.formatMessage(messages.mailBody)}
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        {!isSmallScreen && <Col xl="1" />}
        {isSmallScreen && (
          <Col lg="4" className="text-center">
            <Image src={caseImg} className="mb-3" />
          </Col>
        )}
        <Col lg="7">
          <h4 className={isSmallScreen ? 'text-center' : ''}>
            {intl.formatMessage(messages.creationTitle)}
          </h4>
          <div className="text-justify">
            {intl.formatMessage(messages.creationBody)}
          </div>
        </Col>
        {!isSmallScreen && (
          <Col lg="4" className="text-center">
            <Image src={caseImg} className="mb-3" />
          </Col>
        )}
      </Row>
      <Row className="mb-5">
        <Col lg="4" className="text-center">
          <Image src={contractImg} className="mb-3" />
        </Col>
        <Col lg="7">
          <h4 className={isSmallScreen ? 'text-center' : ''}>
            {intl.formatMessage(messages.contractGenTitle)}
          </h4>
          <div className="text-justify">
            {intl.formatMessage(messages.contractGenBody)}
          </div>
        </Col>
      </Row>
      <Row>
        {!isSmallScreen && <Col lg="1" />}
        {isSmallScreen && (
          <Col lg="4" className="text-center">
            <Image src={phoneImg} className="mb-3" />
          </Col>
        )}
        <Col lg="7">
          <h4 className={isSmallScreen ? 'text-center' : ''}>
            {intl.formatMessage(messages.clientServiceTitle)}
          </h4>
          <div className="text-justify">
            {intl.formatMessage(messages.clientServiceBody)}
          </div>
        </Col>
        {!isSmallScreen && (
          <Col lg="4" className="text-center">
            <Image src={phoneImg} className="mb-3" />
          </Col>
        )}
      </Row>
      <Separator />
      <Row className="mb-5">
        <Col lg="12" className="text-center">
          <h3>{intl.formatMessage(messages.pricingTablesTitle)}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Slider {...settings}>
            <PriceSlide
              tag="starter-pack"
              packName={intl.formatMessage(messages.starterPack)}
              price={69}
              currency="DT"
              duration={intl.formatMessage(messages.month)}
              buttonText={intl.formatMessage(messages.chooseThisPack)}
              content={[
                `+ ${intl.formatMessage(messages.domiciliation)}`,
                `+ ${intl.formatMessage(messages.mailManagement)}`
              ]}
            />
            <PriceSlide
              tag="pro-pack"
              packName={intl.formatMessage(messages.proPack)}
              highlight
              highlightText={intl.formatMessage(messages.bestChoice)}
              price={99}
              currency="DT"
              duration={intl.formatMessage(messages.month)}
              buttonText={intl.formatMessage(messages.chooseThisPack)}
              content={[
                `+ ${intl.formatMessage(messages.domiciliation)}`,
                `+ ${intl.formatMessage(messages.mailManagementTwo)}`
              ]}
            />
            <PriceSlide
              tag="premium-pack"
              packName={intl.formatMessage(messages.premiumPack)}
              price={119}
              currency="DT"
              duration={intl.formatMessage(messages.month)}
              buttonText={intl.formatMessage(messages.chooseThisPack)}
              content={[
                `+ ${intl.formatMessage(messages.domiciliation)}`,
                `+ ${intl.formatMessage(messages.mailManagementThree)}`,
                `+ ${intl.formatMessage(messages.emailManagement)}`
              ]}
            />
          </Slider>
        </Col>
      </Row>
      <Separator />
      <Row>
        <Col
          xl="6"
          lg="6"
          sm="12"
          className={isSmallScreen ? 'target-image' : ''}
        >
          <h3>{intl.formatMessage(messages.targetTitle)}</h3>
          <div className="text-justify">
            {intl.formatMessage(messages.targetBody)}
          </div>
        </Col>
        {!isSmallScreen && (
          <Col xl="6" lg="6">
            <Image src={comunityImg} width="100%" />
          </Col>
        )}
      </Row>
      <Separator />
      <Row className="know-it-all">
        <Col xl="12" className="text-center">
          <Separator />
          <h3 className="know-it-all-text">
            {intl.formatMessage(messages.knowItAllTitle)}
          </h3>
          <div className="sub know-it-all-text">
            {intl.formatMessage(messages.knowItAllSub)}
          </div>
          <Button
            className="mt-5 last-btn"
            theme="light"
            onClick={() => {
              navigate('/contract');
            }}
          >
            {intl.formatMessage(messages.iJoinBtn)}
          </Button>
          <Separator />
        </Col>
      </Row>
      <Modal
        open={openModal}
        toggle={() => {
          setOpenModal(false);
          setModalState('neutral');
        }}
      >
        <ModalHeader>
          {intl.formatMessage(messages.heroBtnSecondary)}
        </ModalHeader>
        <ModalBody>
          <Formik
            validateOnChange
            initialValues={{
              clientName: undefined,
              phoneNumber: undefined
            }}
            validationSchema={Yup.object().shape({
              clientName: Yup.string().matches(
                /^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i,
                intl.formatMessage(messages.nameNoNumbers)
              ),
              phoneNumber: Yup.string().test(
                'phone-number-test',
                intl.formatMessage(messages.phoneValidation),
                value => (value ? validator.isMobilePhone(value) : true)
              )
            })}
          >
            {({ values }) => (
              <>
                {modalState === 'neutral' && (
                  <>
                    <div className="mb-2">
                      {intl.formatMessage(messages.yourName)}
                    </div>
                    <Input name="clientName" className="mb-2" />
                    <div className="mb-2">
                      {intl.formatMessage(messages.yourNumber)}
                    </div>
                    <Input name="phoneNumber" className="mb-2" />
                    <Button
                      className="modal-btn-primary mb-2"
                      theme="success"
                      disabled={
                        !values.clientName ||
                        !values.phoneNumber ||
                        values.clientName.length < 5 ||
                        values.phoneNumber.length < 6
                      }
                      onClick={async e => {
                        e.preventDefault();
                        setModalState('sending');
                        try {
                          const res = await axios.post(
                            '/.netlify/functions/sendcontactinfo',
                            values
                          );
                          if (res.data && res.data.result === 'success') {
                            setModalState('sent');
                          } else {
                            setModalState('error');
                          }
                        } catch (err) {
                          setModalState('error');
                        }
                      }}
                    >
                      {intl.formatMessage(messages.submit)}
                    </Button>
                  </>
                )}
                {modalState === 'sending' && (
                  <h5 className="mb-3 fade-in">
                    {intl.formatMessage(messages.sending)}
                  </h5>
                )}
                {modalState === 'sent' && (
                  <>
                    <h5 className="mb-3 fade-in">
                      {intl.formatMessage(messages.sent)}
                    </h5>
                    <div className="mb-3 delayed-fade-in">
                      {intl.formatMessage(messages.sentMessage)}
                    </div>
                  </>
                )}
                {modalState === 'error' && (
                  <>
                    <h5 className="mb-3 fade-in">
                      {intl.formatMessage(messages.notSent)}
                    </h5>
                    <div className="mb-3 delayed-fade-in">
                      {intl.formatMessage(messages.notSentMessage)}
                    </div>
                  </>
                )}
                <Button
                  className="modal-btn-secondary"
                  theme="light"
                  onClick={() => {
                    setOpenModal(false);
                    setModalState('neutral');
                  }}
                >
                  {intl.formatMessage(messages.close)}
                </Button>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default IndexPage;
