import { defineMessages } from 'react-intl';

export const scope = 'landing';

export default defineMessages({
  darkMode: {
    id: `${scope}.darkMode`,
    defaultMessage: 'Mode nuit'
  },
  heroTitleP1: {
    id: `${scope}.heroTitle`,
    defaultMessage: 'La domiciliation'
  },
  heroTitleP2: {
    id: `${scope}.heroTitleP2`,
    defaultMessage: 'de votre entreprise en Tunisie.'
  },
  heroSub: {
    id: `${scope}.heroSub`,
    defaultMessage: 'The address for your business'
  },
  heroBtnPrimary: {
    id: `${scope}.heroBtnPrimary`,
    defaultMessage: 'GÉNÉRER MON CONTRAT'
  },
  heroBtnSecondary: {
    id: `${scope}.heroBtnSecondary`,
    defaultMessage: 'ÊTRE CONTACTÉ'
  },
  presTitle: {
    id: `${scope}.presTitle`,
    defaultMessage: 'La domiciliation commerciale avec ABDomiciliation'
  },
  presSub: {
    id: `${scope}.presSub`,
    defaultMessage: 'La première étape de la création de votre projet'
  },
  presBody: {
    id: `${scope}.presBody`,
    defaultMessage:
      'Vous cherchez une société de domiciliation, moderne rapide et accessible? ABDomiciliation domicilie votre entreprise à partir de 69 TND HT par mois et met à votre disposition toute une gamme de services pensée pour vous simplifier la vie.'
  },
  contractTitle: {
    id: `${scope}.contractTitle`,
    defaultMessage: 'Contract en quelques clics'
  },
  contractSub: {
    id: `${scope}.contractSub`,
    defaultMessage: 'Votre contrat en quelques minutes'
  },
  stepOne: {
    id: `${scope}.stepOne`,
    defaultMessage: 'Choisir votre pack préféré'
  },
  stepTwo: {
    id: `${scope}.stepTwo`,
    defaultMessage: 'Remplir le contract'
  },
  stepThree: {
    id: `${scope}.stepThree`,
    defaultMessage: 'Télécharger votre contrat'
  },
  servicesTitle: {
    id: `${scope}.servicesTitle`,
    defaultMessage: 'Nos services'
  },
  feesTitle: {
    id: `${scope}.feesTitle`,
    defaultMessage: 'Tarifs transparents'
  },
  feesBody: {
    id: `${scope}.feesBody`,
    defaultMessage:
      'Des tarifs bien étudiés, fixes, sans frais cachés, caution ou dépôt de garantie. ABDomiciliation vous offre une structure de tarifs intéressante et une stratégie de payement facile.'
  },
  addressTitle: {
    id: `${scope}.addressTitle`,
    defaultMessage: 'Adresse prestigieuse'
  },
  addressBody: {
    id: `${scope}.addressBody`,
    defaultMessage:
      'La domiciliation d’entreprise est le moyen le plus rapide et le plus économique pour lancer votre entreprise. Avec la domiciliation en ligne vous pouvez, en seulement quelques clics, obtenir une adresse de siège social, quel que soit le statut de votre projet. 25, Rue de l’île de Zembretta, Villa N°2, Les Jardins du Lac, Lac II, 1053, Tunis, Tunisie.'
  },
  mailTitle: {
    id: `${scope}.mailTitle`,
    defaultMessage: 'Gestion de courrier'
  },
  mailBody: {
    id: `${scope}.mailBody`,
    defaultMessage:
      'ABDomiciliation vous propose une gestion de courrier qui consiste à réceptionner les courriers et les classer, à les réexpédier vers une adresse de votre choix par voie postale, à les numériser par scan et à les envoyer par mail sous 24h. Aussi pratique qu’économique !'
  },
  creationTitle: {
    id: `${scope}.creationTitle`,
    defaultMessage: "Création d'entreprise"
  },
  creationBody: {
    id: `${scope}.creationBody`,
    defaultMessage:
      'Entreprise c’est l’image, la notoriété, la cohérence marketing et commerciale. ABDomiciliation vous aide à choisir la forme juridique la plus adaptée à vos besoins et votre activité (SA, SARL, SUARL, Association, Micro-entrepreneur, Freelancers…)'
  },
  contractGenTitle: {
    id: `${scope}.contractGenTitle`,
    defaultMessage: 'Contrat en ligne'
  },
  contractGenBody: {
    id: `${scope}.contractGenBody`,
    defaultMessage:
      'La génération de votre contrat est simple, rapide et en quelques minutes. Vous pouvez télécharger immédiatement votre contrat et vous en recevrez une copie par e-mail.'
  },
  clientServiceTitle: {
    id: `${scope}.clientServiceTitle`,
    defaultMessage: 'Service client réactif'
  },
  clientServiceBody: {
    id: `${scope}.clientServiceBody`,
    defaultMessage:
      'Nous vous accompagnons dès la construction de votre projet.'
  },
  targetTitle: {
    id: `${scope}.targetTitle`,
    defaultMessage: 'La domiciliation, pour qui?'
  },
  targetBody: {
    id: `${scope}.targetBody`,
    defaultMessage:
      "Nos services s'adressent à tous! SARL, SUARL, Associations, Auto-entrepreneurs, Micro-entreprises, Freelancers, Indépendants, Professions libérales, Avocats et plus."
  },
  knowItAllTitle: {
    id: `${scope}.knowItAllTitle`,
    defaultMessage: 'Vous savez tout!'
  },
  knowItAllSub: {
    id: `${scope}.knowItAllSub`,
    defaultMessage: 'Votre adresse de domiciliation vous tends les bras'
  },
  iJoinBtn: {
    id: `${scope}.iJoinBtn`,
    defaultMessage: 'JE ME DOMICILIE'
  },
  pricingTablesTitle: {
    id: `${scope}.pricingTablesTitle`,
    defaultMessage: 'Nos packs de domicialisation'
  },
  starterPack: {
    id: `${scope}.starterPack`,
    defaultMessage: 'Starter Pack'
  },
  proPack: {
    id: `${scope}.proPack`,
    defaultMessage: 'Pro Pack'
  },
  premiumPack: {
    id: `${scope}.premiumPack`,
    defaultMessage: 'Premium Pack'
  },
  bestChoice: {
    id: `${scope}.bestChoice`,
    defaultMessage: 'Meilleur Choix'
  },
  month: {
    id: `${scope}.month`,
    defaultMessage: 'mois'
  },
  chooseThisPack: {
    id: `${scope}.chooseThisPack`,
    defaultMessage: 'Choisir ce pack'
  },
  domiciliation: {
    id: `${scope}.domiciliation`,
    defaultMessage: 'Domiciliation'
  },
  starterPackAnd: {
    id: `${scope}.starterPackAnd`,
    defaultMessage: 'Starter Pack et:'
  },
  emailManagement: {
    id: `${scope}.emailManagement`,
    defaultMessage: 'La transmission instantanée du courrier par internet'
  },
  premiumPackAnd: {
    id: `${scope}.premiumPackAnd`,
    defaultMessage: 'Pro Pack et:'
  },
  mailManagement: {
    id: `${scope}.mailManagement`,
    defaultMessage: 'La réception et la mise à disposition de votre courrier'
  },
  mailManagementTwo: {
    id: `${scope}.mailManagementTwo`,
    defaultMessage:
      'La réexpédition du courrier par voie postale vers l’adresse de votre choix'
  },
  mailManagementThree: {
    id: `${scope}.mailManagementThree`,
    defaultMessage: 'La réexpédition du courrier par voie postale'
  },
  yourName: {
    id: `${scope}.yourName`,
    defaultMessage: 'Votre nom'
  },
  yourNumber: {
    id: `${scope}.yourNumber`,
    defaultMessage: 'Votre numéro de téléphone'
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Envoyer'
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Fermer'
  },
  nameNoNumbers: {
    id: `${scope}.nameNoNumbers`,
    defaultMessage:
      'Votre nom ne doit contenir que des caractères alphabétiques'
  },
  phoneValidation: {
    id: `${scope}.phoneValidation`,
    defaultMessage: 'Votre numéro doit être de format valide'
  },
  sending: {
    id: `${scope}.sending`,
    defaultMessage: 'Envoi en cours...'
  },
  notSentMessage: {
    id: `${scope}.notSentMessage`,
    defaultMessage: "Une erreur s'est produite"
  },
  notSent: {
    id: `${scope}.notSent`,
    defaultMessage: 'Opps!'
  },
  sent: {
    id: `${scope}.sent`,
    defaultMessage: 'Parfait!'
  },
  sentMessage: {
    id: `${scope}.sentMessage`,
    defaultMessage: 'Nous vous contacterons bientôt. Merci.'
  }
});
