import React from 'react';
import { Button } from 'shards-react';
import { nanoid } from 'nanoid';
import { navigate } from 'gatsby';

const PriceSlide = props => {
  const {
    packName,
    highlight,
    highlightText,
    price,
    currency,
    duration,
    buttonText,
    content,
    tag
  } = props;

  return (
    <div className="d-flex justify-content-center">
      <div
        className={`d-flex align-items-start flex-column price-slide-container${
          highlight ? '-highlighted' : ''
        } p-4`}
      >
        <div className="mb-auto w-100">
          <div className="text-right text-uppercase mb-3">{highlightText}</div>
          <h5 className="text-center mb-4">{packName}</h5>
          <div className="pack-name-underline" />
          {content && content.length !== 0 && (
            <>
              {content.map(el => (
                <div className="mt-5" key={nanoid()}>
                  {el}
                </div>
              ))}
            </>
          )}
        </div>
        <div className="w-100">
          <div className="d-flex justify-content-center">
            <h3 className="text-center mb-4">{price}</h3>
            <div className="pt-2">{currency}</div>
            <h3>/{duration}</h3>
            <div className="pt-2">(HT)</div>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="pricing-btn"
              theme="light"
              onClick={() => {
                const params = new URLSearchParams({ pack: tag }).toString();
                navigate(`/contract?${params}`);
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceSlide;
